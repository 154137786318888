document.addEventListener("DOMContentLoaded", () => {
    $('.keep-open').click(function(e) {
        if (/input|label/i.test(e.target.tagName)){
            var parent = $(e.target).parent();
            if(parent.hasClass('checkbox')){
                var checkbox = parent.find('input[type=checkbox]');
                checkbox.prop("checked", !checkbox.prop("checked"));
                return false;
            }
        }
    });
    $("#tableColSetting").click(function(event){
        event.preventDefault()
        var get_chekbox = $(this).attr("data-check")
        var selected = new Array();
        $('input[name="'+ get_chekbox+'"]:checked').each(function() {
            selected.push($(this).val());
        });
        var data = {
            preference_name: $(this).attr("data-pref"),
            preference_value: selected
        }
        $.ajax({
            url: '/my-profile/preferences',
            method: 'POST',
            data: data,
            dataType: "json",
            success: function (data) {
            window.location.reload()
            }})
        });
    }
);
