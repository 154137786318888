import { Controller } from 'stimulus'
import intlTelInput from 'intl-tel-input'
// https://github.com/jackocnr/intl-tel-input

const DUMMY_NUMBER = "912222333444"
const MOBILE_NOT_PROVIDED = "Not Provided"

export default class extends Controller {
  static targets = ['countryCodeInput']

  connect() {
    let iti = null
    const countryCodeInput = this.countryCodeInputTarget
    const allowChangingCountryCode = countryCodeInput.dataset.allowChangingCountryCode === "true"

    iti = intlTelInput(countryCodeInput, {
      utilsScript: "/intl-tel-input/build/js/utils.js",
      initialCountry: countryCodeInput.value !== DUMMY_NUMBER ? countryCodeInput.dataset.initialCountry : null,
      separateDialCode: true,
      formatOnDisplay: false,
      allowDropdown: allowChangingCountryCode ? true : false
    })

    let data = iti.getSelectedCountryData()
    let dialCode = data.dialCode
    const regex = /^\d+$/

    if (regex.test(countryCodeInput.value) && countryCodeInput.value != DUMMY_NUMBER) {
      countryCodeInput.value = countryCodeInput.value.slice(dialCode.length)
    } else if (countryCodeInput.value == DUMMY_NUMBER) {
      countryCodeInput.value = MOBILE_NOT_PROVIDED
    }

    countryCodeInput.addEventListener("countrychange", (event) => {
      data = iti.getSelectedCountryData()
    })

    countryCodeInput.form.addEventListener("submit", (event) => {
      if (Object.keys(data).length === 0) {
        iti.setCountry(countryCodeInput.dataset.initialCountry)

        dialCode = iti.getSelectedCountryData().dialCode
      } else {
        dialCode = data.dialCode
      }

      if (!regex.test(countryCodeInput.value) ||
        countryCodeInput.value.slice(0, dialCode.length) == dialCode ||
        countryCodeInput.value.slice(0, dialCode.length * 2) == `${dialCode}${dialCode}`)
      {
        return
      } else {
        countryCodeInput.value = `${dialCode}${countryCodeInput.value}`
      }
    })
  }

  addNotProvidedText(event) {
    this.countryCodeInputTarget.value = MOBILE_NOT_PROVIDED
  }
}
