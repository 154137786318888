import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
  const initiateToastPopupChannel = document.querySelector('#initiate-toast-popup-channel')

  if (!initiateToastPopupChannel) return

  consumer.subscriptions.create({ channel: "ToastPopupChannel", user_id: initiateToastPopupChannel.dataset.userId }, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      if (document.querySelector(data.skip_popup_if_selector_exists_in_dom)) return

      $(document).Toasts('create', data.toast_popup_config)

      $(".notification-play")[0].currentTime = 0
      $(".notification-play")[0].play()
    }
  })
})
