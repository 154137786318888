import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  // add class 'active'
  add() {
    let selector = this.data.get('selector')
    let elements = this._findElements(selector)
    for (let element of elements) {
      element.classList.add('active')
    }
    console.log(`activate#add ${selector}`)
  }

  // toggle class 'active'
  // <%= button_tag class: "btn btn-link", 'data-controller': 'activate', 'data-action': 'activate#toggle', 'data-activate-selector': '#sidebar,.sidebar__overlay' do %>
  //
  // <div class='sidebar__overlay' data-controller='activate' data-action='click->activate#toggle' data-activate-selector='#sidebar,.sidebar__overlay'></div>
  toggle() {
    let selector = this.data.get('selector')
    let elements = this._findElements(selector)
    for (let element of elements) {
      element.classList.toggle('active')
    }
    console.log(`activate#toggle ${selector}`)
  }

  // toggle and remove self element
  toggleAndRemoveMe() {
    this.toggle()
    this.element.innerHTML = ''
  }

  // Use for checkbox to enable disable selector
  // <div id="migrate-form" data-controller="activate" data-activate-selector="#migrate-btn">
  //    <%= check_box_tag :agree, '1', false, 'data-action': 'activate#enableIfChecked activate#toggle' %>
  enableIfChecked(event) {
    let checked = event.target.checked
    let selector = this.data.get('selector')
    let elements = this._findElements(selector)
    for (let element of elements) {
      if (checked) {
        element.disabled = false
      } else {
        element.disabled = true
      }
    }
    console.log(`activate#enableIfChecked ${selector}`)
  }

  // this is for radio button with some VALUE
  // enable and disable targets which has data value that contains VALUE
  enableDisableForValue(event) {
    let selector = this.data.get('selector')
    let elements = this._findElements(selector)
    let value = event.target.value
    for (let element of elements) {
      if (!element.dataset.activateValue) {
        continue
      }
      if (element.dataset.activateValue == 'toggleBtn') {
        element.toggleAttribute('disabled')
      }
      else if (element.dataset.activateValue.indexOf(value) != -1) {
        element.classList.add('active')
        element.disabled = false
        for(let input of element.querySelectorAll('input,select')) {
          input.disabled = false
        }
      } else {
        element.classList.remove('active')
        element.disabled = true
        for(let input of element.querySelectorAll('input,select')) {
          input.disabled = true
        }
      }
    }
    console.log(`activate#enableDisableForValue ${selector} ${value}`)
  }

  _findElements(selectors) {
    let elements = []
    for (let selector of selectors.split(',')) {
      if (selector[0] == '#') {
        let element_by_id = document.getElementById(selector.substring(1))
        if (element_by_id) elements.push(element_by_id)
      } else if (selector[0] == '.') {
        let elements_by_class = document.getElementsByClassName(selector.substring(1))
        elements = elements.concat(Array.from(elements_by_class))
      } else {
        let elements_by_tag_name = document.getElementsByTagName(selector)
        elements = elements.concat(Array.from(elements_by_tag_name))
      }
    }
    return elements
  }

}
