import { Controller } from 'stimulus'

export default class extends Controller {
  toggle(e) {
    e.preventDefault()
    let key = e.currentTarget.dataset.checkTarget
    let keyStrip = this._strip(key)
    if (this.data.get(keyStrip) == 'enabled') {
      this.data.set(keyStrip, '')
      $(key).prop('checked', false)
      e.currentTarget.text = `Check ${e.currentTarget.dataset.checkName || ''}`
      console.log('unckeck keyStrip=' + keyStrip + ' data='+this.data.get(keyStrip))
    } else {
      this.data.set(keyStrip, 'enabled')
      $(key).prop('checked', true)
      e.currentTarget.text = `Uncheck ${e.currentTarget.dataset.checkName || ''}`
      console.log('ckeck keyStrip=' + keyStrip + ' data='+this.data.get(keyStrip))
    }
  }

  _strip (str) {
    return str.replace(/[[\]=]/g,'')
  }
}
