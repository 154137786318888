/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
require("channels")

// attach jQuery so it is available for AdminLTE
window.$ = window.jQuery = jQuery;

const trkDatatables = require('trk_datatables')

import "../stylesheets/application.scss"
require('bootstrap/js/dist/toast')
require('admin-lte')
require("./lightdark")
require("./table_colum")
require('datatables.net-buttons-bs4/js/buttons.bootstrap4.min')

import "@fortawesome/fontawesome-free/js/all.min"
import "overlayscrollbars/js/jquery.overlayScrollbars.min"
import "chart.js/dist/Chart.min"
import 'intl-tel-input/build/css/intlTelInput.css'

document.addEventListener('DOMContentLoaded', () => {
  console.log('ready page:load webpacker')

   $('[data-toggle="tooltip"]').tooltip()

  var navbar = document.querySelector('.navbar')
  if(navbar)
  {
    var navbar_height = navbar.offsetHeight;
    document.body.style.paddingTop = navbar_height + 'px';
  }
})

$(document).ajaxComplete(function() {
  // Required for Bootstrap tooltips in DataTables
  $('[data-toggle="tooltip"]').tooltip();
});

const exportOptions = {
    format: {
        body: function (title, row, column, node) {
            const withoutTitles = node.innerHTML.replace(/title="[^"]*"/g, '').replace(/<[^>]*>/g, ' ');
            return withoutTitles
        }
    }
};

window.initPage = function(val){
    document.addEventListener('DOMContentLoaded', () => {
        if(val == true){
            // this will initialise all data-datatables elements
            trkDatatables.initialise({
              dateRangePickerDateTimeFormat: 'DD-MMM-YYYY hh:mm:ss A',
              dom: 'B<"trk-global-search-wrapper"f>rtp<"trk-move-up"il>',
              buttons: [
                  {
                      extend: 'excel',
                      className: 'ml-1',
                      exportOptions: exportOptions
                  },
                  {
                      extend: 'csv',
                      exportOptions: exportOptions
                  },
                  {
                      extend: 'print',
                      exportOptions: exportOptions
                  },
              ]
            }); 
        }
        else {
            // this will initialise all data-datatables elements
            trkDatatables.initialise({
                // should match config/initializers/time_formats.rb Time::DATE_FORMATS[:default] = '%d-%b-%Y %I:%M %p'
                dateRangePickerDateTimeFormat: 'DD-MMM-YYYY hh:mm:ss A'
            })

        }
    })
}

