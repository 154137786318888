import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
  const initiateXceednetTicketChatMessageChannel = document.querySelector('#initiate-xceednet-ticket-chat-message-channel');

  if (!initiateXceednetTicketChatMessageChannel) return

  consumer.subscriptions.create({channel: "XceednetTicketChatMessageChannel", xceednet_ticket_id: initiateXceednetTicketChatMessageChannel.dataset.xceednetTicketId }, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      const userId = initiateXceednetTicketChatMessageChannel.dataset.userId
      let html;

      if (Number(userId) === data.message.user_id) {
        html = data.mine
      } else {
        html = data.theirs
        $(".chat-play")[0].currentTime = 0;
        $(".chat-play")[0].play();
      }

      const messageContainer = document.querySelector('#xceednet-ticket-chat-messages')
      messageContainer.innerHTML = messageContainer.innerHTML + html
      messageContainer.scrollTop = objDiv.scrollHeight;
    }
  });
})
