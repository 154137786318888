
document.addEventListener("DOMContentLoaded", () => {
    if ($("body").hasClass("dark-mode")) {
        $("#lightDark").html('<h5 class="pr-2 text-yellow"><i class="fas fa-sun"></i> </h5>')
    }
    else {
        $("#lightDark").html('<h5 class="pr-2"><i class="fas fa-moon"></i> </h4>')
    }
    $("#lightDark").click(function(){
        var dark_mode = ! $('body').hasClass('dark-mode')
        var data = {
            preference_name: 'dark_mode',
            preference_value: dark_mode
        }
        $.ajax({
            url: '/my-profile/preferences',
            method: 'POST',
            data: data,
            dataType: "json",})

        if ($("body").hasClass("dark-mode")) {
            $("body").removeClass("dark-mode");
            $("#lightDark").html('<h5 class="pr-2"><i class="fas fa-moon"></i> </h5>')
            var $main_header = $('.main-header')
            $main_header.removeClass('navbar-dark').addClass('navbar-white')
        }
        else {
            $("body").addClass("dark-mode");
            var $main_header = $('.main-header')
            $main_header.removeClass('navbar-white').addClass('navbar-dark')
            $("#lightDark").html('<h5 class="pr-2 text-yellow"><i class="fas fa-sun"></i> </h5>')
        }
    });
    }
    );
