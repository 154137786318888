import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "card", "chart", "chartTypesList", "chartTypesBtn", "chartItem" ]

  chartInstance = null
  labels = []
  values = []
  backgroundColorsForChartOptions = [
    '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5',
    '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005'
  ]

  // Check location_dashboard/index.html.erb, layouts/_graphic_view.html.erb and subscriber/dashboard/index.html.erb for usage examples

  connect() {
    const noToggleChartDisplay = this.element.dataset.noToggleChartDisplay === "true"

    if (noToggleChartDisplay) {
      this._initOrUpdateChart()
    }
  }

  toggleChartDisplay(event) {
    if (!this.chartInstance) {
      this._initOrUpdateChart()
    } else {
      this.chartInstance.destroy()
      this.chartInstance = null
    }

    this.chartTypesBtnTarget.classList.toggle('hidden')
    this.chartTypesListTarget.classList.add('hidden')
  }

  changeChartType(event) {
    const listItem = event.currentTarget

    if (this.chartTarget.dataset.type !== listItem.dataset.label) {
      this.chartTarget.dataset.type = listItem.dataset.label

      this.chartTypesListTarget.children.forEach((listItemChild) => {
        if (listItemChild.dataset.label !== listItem.dataset.label) {
          listItemChild.classList.remove('active')
        } else {
          listItem.classList.add('active')
        }
      })
      this.chartTypesListTarget.classList.add('hidden')
      this._initOrUpdateChart()
    }
  }

  toggleChartOptions(event) {
    this.chartTypesListTarget.classList.toggle('hidden')
  }

  _initOrUpdateChart() {
    const chart = this.chartTarget
    this._clearChartData()
    this._populateChartData()

    const chartCanvas = chart.getContext('2d')
    const chartData = {
      labels: this.labels,
      datasets: [{
        label: `${chart.dataset.model} Dataset`,
        data: this.values,
        backgroundColor: this.backgroundColorsForChartOptions,
      }]
    }

    const chartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      onHover: (event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            return data.labels[tooltipItem.index]
          },
          title: function() {
            return ''
          }
        }
      },
      plugins: {
        labels: {
          render: function() {
            return ""
          },
          font: {
            size: 12
          }
        },
      }
    }

    if (!this.chartInstance) {
      this.chartInstance = this._newChartInstance(chartCanvas, chartData, chart.dataset.type, chartOptions)
    } else {
      this.chartInstance.destroy()
      this.chartInstance = this._newChartInstance(chartCanvas, chartData, chart.dataset.type, chartOptions)
      this.chartInstance.update()
    }
  }

  _clearChartData() {
    this.labels = []
    this.values = []
  }

  _newChartInstance(chartCanvas, chartData, chartType, chartOptions) {
    return new Chart(chartCanvas, {
      type: chartType,
      data: chartData,
      options: chartOptions
    })
  }

  canvasClick(event) {
    const firstPoint = this.chartInstance.getElementAtEvent(event)[0]

    if (firstPoint) {
      const label = this.chartInstance.data.labels[firstPoint._index]
      this._redirect(label)
    }
  }

  _redirect(label) {
    this.chartItemTargets.forEach((chartItem) => {
      if (chartItem.dataset.label == label) {
        chartItem.dataset.link ? window.open(`${chartItem.dataset.link}`) : ''
      }
    })
  }

  _populateChartData() {
    this.labels = []
    this.values = []

    const chartType = this.chartTarget.dataset.type
    this.chartItemTargets.forEach((chartItem) => {
      const label = chartItem.dataset.label
      const value = chartItem.dataset.value
      const date = chartItem.dataset.date
      const humanReadableValue = chartItem.dataset.humanReadableValue
      let updatedLabel = `${label} (${value})`

      if (date) {
        const [day, month, year] = date.split('-')
        updatedLabel = `${label} - ${day}-${month}`
      }

      if (humanReadableValue) {
        updatedLabel = `${updatedLabel} (${humanReadableValue})`
      }

      const chartItemType = chartItem.dataset.chartTypeSet
      if (!chartItemType || chartItemType.split(" ").includes(chartType)) {
        this.labels.push(updatedLabel)
        this.values.push(value)
      }
    })
  }
}
