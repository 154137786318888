import { Controller } from "stimulus"

let fhr = 12
let fmi = 0
let ampm = 'AM'

const timepickerHTML =
`<div class="tpicker hidden" data-timepicker-target="timepickerContainer">
  <span data-timepicker-target="closeTimepickerBtn" data-action="click->timepicker#closeTimepicker"><i class="fa fa-times" aria-hidden="true"></i></span>
  <div class="pk1">
    <div class="d-flex align-items-center" data-timepicker-target="timepickerBody">
      <div class="hr d-flex flex-column align-items-center">
        <span class="hrup" data-action="click->timepicker#increaseHour"><i class="fa fa-angle-up"></i></span>
        <a class="hrhere" data-timepicker-target="hrDisplay" data-action="click->timepicker#toggleHourOptionsTable">12</a>
        <span class="hrdown" data-action="click->timepicker#decreaseHour"><i class="fa fa-angle-down"></i></span>
      </div>
      :
      <div class="hr d-flex flex-column align-items-center">
        <span class="minup" data-action="click->timepicker#increaseMinute"><i class="fa fa-angle-up"></i></span>
        <a class="minhere" data-timepicker-target="minDisplay" data-action="click->timepicker#toggleMinuteOptionsTable">00</a>
        <span class="mindown" data-action="click->timepicker#decreaseMinute"><i class="fa fa-angle-down"></i></span>
      </div> 
      <button type="button" class="btn btn-primary medchange" data-timepicker-target="changeAMPM" data-action="click->timepicker#changeAMPM">AM</button>
    </div> 
    <div class="pk2 hidden" data-timepicker-target="minOptions">
      <table class="table table-bordered" data-timepicker-target="minTable" data-action="click->timepicker#updateTimeFromMinuteOptionsTable">
        <tr>
          <td>00</td>
          <td>05</td>
          <td>10</td>
          <td>15</td>
        </tr>
        <tr>
          <td>20</td>
          <td>25</td>
          <td>30</td>
          <td>35</td>
        </tr>
        <tr>
          <td>40</td>
          <td>45</td>
          <td>50</td>
          <td>55</td>
        </tr>
      </table>
    </div>
    <div class="pk2 hidden" data-timepicker-target="hrOptions">
      <table class="table table-bordered" data-timepicker-target="hrTable" data-action="click->timepicker#updateTimeFromHourOptionsTable">
        <tr>
          <td>01</td>
          <td>02</td>
          <td>03</td>
          <td>04</td>
        </tr>
        <tr>
          <td>05</td>
          <td>06</td>
          <td>07</td>
          <td>08</td>
        </tr>
        <tr>
          <td>09</td>
          <td>10</td>
          <td>11</td>
          <td>12</td>
        </tr>
      </table>
    </div>
  </div>
</div>`

export default class extends Controller {
  static targets = [ 
    "timepicker", "timepickerAnchor", "timepickerContainer", 
    "timepickerHead", "timepickerBody", "timeDisplay", 
    "minDisplay", "hrDisplay", "changeAMPM",
    "hrOptions", "minOptions", "closeTimepickerBtn",
    "openTimepickerBtn", "checkbox"
    
  ]

  connect() {
    this.timepickerAnchorTarget.insertAdjacentHTML('afterbegin', timepickerHTML)

    if (this.checkboxTarget.checked){
      this._show(this.timeDisplayTarget)
      this._show(this.openTimepickerBtnTarget)
    }
  }

  toggleTimepickerTextField(event) {
    if (event.target.checked) {
      this._show(this.timeDisplayTarget)
      this._show(this.openTimepickerBtnTarget)
    } else {
      this.timeDisplayTarget.value = null
      this._hide(this.timeDisplayTarget)
      this._hide(this.openTimepickerBtnTarget)
    }
  }

  toggleTimepicker(event) {
    this.timepickerContainerTarget.classList.toggle('hidden')
    this._updateTime()

    if (this.hrOptionsTarget)
      this._hide(this.hrOptionsTarget)
      this._show(this.timepickerBodyTarget)
    if (this.minOptionsTarget)
      this._hide(this.minOptionsTarget)
      this._show(this.timepickerBodyTarget)
  }

  closeTimepicker(event) {
    this._hide(this.timepickerContainerTarget)
  }

  windowCloseTimepicker(event) {
    if (event.target != this.openTimepickerBtnTarget && !Array.from(this.openTimepickerBtnTarget.querySelectorAll("*")).includes(event.target)) {

      if (!Array.from(this.timepickerContainerTarget.querySelectorAll("*")).includes(event.target))
        this._hide(this.timepickerContainerTarget)
    }
  }

  increaseHour(event) {
    if (fhr < 12) {
      fhr++
      this._updateTime()
    } else {
      fhr = 1
      this._updateTime()
    }
    
  }
  decreaseHour(event) {
    if (fhr > 1) {
      fhr--
      this._updateTime()
    } else {
      fhr = 12
      this._updateTime()
    }
  }
  increaseMinute(event) {
    if (fmi < 59) {
      fmi++
      this._updateTime()
    } else {
      fmi = 0
      this._updateTime()
    }
  }
  decreaseMinute(event) {
    if (fmi > 0) {
      fmi--
      this._updateTime()
    } else {
      fmi = 59
      this._updateTime()
    }
  }
  changeAMPM(event) {
    if (ampm == 'AM') {
      ampm = 'PM'
      this._updateTime()
    } else {
      ampm = 'AM'
      this._updateTime()
    }
  }

  toggleHourOptionsTable(event) {
    this._toggle(this.hrOptionsTarget)
    this._hide(this.minOptionsTarget)
    this._hide(this.timepickerBodyTarget)
  }
  toggleMinuteOptionsTable(event) {
    this._toggle(this.minOptionsTarget)
    this._hide(this.hrOptionsTarget)
    this._hide(this.timepickerBodyTarget)
  }
  updateTimeFromHourOptionsTable(event) {
    let val = event.target.innerHTML
    this._hide(this.hrOptionsTarget)
    this._hide(this.minOptionsTarget)
    this._show(this.timepickerBodyTarget)
    fhr = parseInt(val)
    this._updateTime()
  }
  updateTimeFromMinuteOptionsTable(event) {
    let val = event.target.innerHTML
    this._hide(this.hrOptionsTarget)
    this._hide(this.minOptionsTarget)
    this._show(this.timepickerBodyTarget)
    fmi = parseInt(val)
    this._updateTime()
  }

  _updateTime(){
    if (ampm == 'PM') {
      ampm = "PM"
    }
    let thr = fhr
    let tmin = fmi
    if (ampm == 'PM'){
      if (fhr < 12) {
        thr = fhr + 12
      } else if (fhr == 12) {
        thr = 0
      }
    }
    this.timeDisplayTarget.value = ("0" + thr).slice(-2) + ":" + ("0" + tmin).slice(-2)
    this.hrDisplayTarget.innerHTML = ("0" + fhr).slice(-2)
    this.minDisplayTarget.innerHTML = ("0" + fmi).slice(-2)
    this.changeAMPMTarget.innerHTML = ampm
  }

  _show(element) {
    element.classList.remove('hidden')
  }
  _hide(element) {
    element.classList.add('hidden')
  }
  _toggle(element) {
    element.classList.toggle('hidden')
  }

}

